<template>
  <div class="terms-container container p-2 p-md-5">
    <div class="card extra-semi-transparent-card p-4 p-md-5">
      <p class="text-center">
        <strong>COMMUNITY CARE CONNECTION INC. </strong>
      </p>
      <p class="text-center mb-5">
        <strong>Terms of Service Agreement</strong>
      </p>
      <p>
        <strong>Acceptance of Terms of Service.</strong> This is an agreement
        between [COMMUNITY CARE CONNECTION INC.] ("Company"), the owner and
        operator of [C3 portal] (the "Site"), and you ("you" or "You"), a user
        of the Site. By using the Site, you acknowledge and agree to these Terms
        of Service and also the Privacy Policy, which can be found at
        <router-link to="/privacy-policy">here</router-link>
        and is incorporated by reference. If you choose to not agree with any of
        these terms, you may not use the Site.
      </p>
      <ol class="decimal_type main_list" style="list-style-type: decimal">
        <li>
          <strong>Changes to Terms of Service</strong>
          <ol class="decimal_type" style="list-style-type: decimal">
            <li>
              <strong>Right to Change Terms</strong>. The Company reserves the
              right, in its sole discretion, to change these Terms of Service
              (&quot;Updated Terms&quot;) from time to time.
            </li>
            <li>
              <strong>Notice of Updated Terms</strong>. Unless the Company makes
              a change for legal or administrative reasons, the Company will
              provide reasonable advance notice before the Updated Terms become
              effective. You agree that the Company may notify you of the
              Updated Terms by posting them on the Site.
            </li>
            <li>
              <strong>Acceptance of Updated Terms</strong>. Your use of the Site
              after the effective date of the Updated Terms constitutes your
              agreement to the Updated Terms. You should review these Terms of
              Service and any Updated Terms before using the Site.
            </li>
            <li>
              <strong>Effective Date of Updated Terms</strong>. The Updated
              Terms will be effective as of the time of posting, or such later
              date as may be specified in the Updated Terms, and will apply to
              your use of the Site from that point forward.
            </li>
          </ol>
        </li>
        <li>
          <strong>Your Account</strong>
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              <strong>Account Creation</strong>. You must complete the
              registration process by providing the Company with current,
              complete, and accurate information as prompted by the applicable
              registration form. You also will choose a password and a username.
            </li>
            <li>
              <strong>Responsibility for Account</strong>. You are entirely
              responsible for maintaining the confidentiality of your password
              and account. Furthermore, you are entirely responsible for any and
              all activities that occur under your account. You agree to notify
              the Company immediately of any unauthorized use of your account or
              any other breach of security.
            </li>
            <li>
              <strong>Liability for Account Misuse</strong>. The Company will
              not be liable for any loss that you may incur as a result of
              someone else using your password or account, either with or
              without your knowledge. You could be held liable for losses
              incurred by the Company or another party due to someone else using
              your account or password.
            </li>
            <li>
              <strong>Use of Other Accounts</strong>. You may not use anyone
              else&apos;s account at any time, without the permission of the
              account holder.
            </li>
          </ol>
        </li>
        <li>
          <strong>Account Security</strong>. The Company cares about the
          integrity and security of your personal information. However, the
          Company cannot guarantee that unauthorized third parties will never be
          able to defeat the Site&apos;s security measures or use any personal
          information you provide to us for improper purposes. You acknowledge
          that you provide your personal information at your own risk.
        </li>
        <li>
          <strong>License</strong>. During the term of this agreement, the
          Company grants you a limited, non-exclusive, non-transferable license
          to access the Site for your personal and non-commercial use in
          accordance with the Terms of Service.
        </li>
        <li>
          <strong>Intellectual Property Rights</strong>. The design, trademarks,
          service marks, and logos of the Site (&quot;Marks&quot;), are owned by
          or licensed to the Company, subject to copyright and other
          intellectual property rights under United States and foreign laws and
          international conventions. The Company reserves all rights not
          expressly granted in and to the Site. You agree to not engage in the
          use, copying, or distribution of any of the Site other than expressly
          permitted.
        </li>
        <li>
          <strong>User Conduct</strong>
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              You may not engage in any of the following prohibited activities:
              <ol type="a" style="list-style-type: lower-alpha">
                <li>
                  copying, distributing, or disclosing any part of the Site in
                  any medium, including without limitation by any automated or
                  non-automated &quot;scraping&quot;,
                </li>
                <li>
                  using any automated system, including without limitation
                  &quot;robots,&quot; &quot;spiders,&quot; &quot;offline
                  readers,&quot; etc., to access the Site,
                </li>
                <li>
                  transmitting spam, chain letters, or other unsolicited email,
                </li>
                <li>
                  attempting to interfere with, compromise the system integrity
                  or security of, or decipher any transmissions to or from the
                  servers running the Site,
                </li>
                <li>
                  taking any action that imposes or may impose, at our sole
                  discretion, an unreasonable or disproportionately large load
                  on Site infrastructure,
                </li>
                <li>
                  uploading invalid data, viruses, worms, or other software
                  agents through the Site,
                </li>
                <li>
                  collecting or harvesting any personally identifiable
                  information, including account names, from the Site,
                </li>
                <li>
                  using the Site for any commercial solicitation purposes,
                </li>
                <li>
                  impersonating another person or otherwise misrepresenting your
                  affiliation with a person or entity, conducting fraud, hiding
                  or attempting to hide your identity,
                </li>
                <li>interfering with the proper working of the Site,</li>
                <li>
                  accessing any content on the Site through any technology or
                  means other than those provided or authorized by the Site, or
                </li>
                <li>
                  bypassing the measures we may use to prevent or restrict
                  access to the Site, including without limitation, features
                  that prevent or restrict use or copying of any content or
                  enforce limitations on use of the Service or the content.
                </li>
              </ol>
            </li>
            <li>
              You also agree to abide by the Community Standards found at
              [COMMUNITY STANDARDS URL].
            </li>
          </ol>
        </li>
        <li>
          <strong>User Content</strong>
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              <strong>Content Ownership</strong>. You retain all ownership
              rights to content uploaded to the Site.
            </li>
            <li>
              <strong>Content License</strong>. By submitting content to the
              Site, you grant the Company a worldwide, non-exclusive,
              royalty-free, sublicensable, and transferable license to use,
              reproduce, distribute, prepare derivative works of, display, and
              perform the content in connection with the Site and the
              Company&apos;s (and its successors&apos; and affiliates&apos;)
              business, including without limitation for promoting and
              redistributing part or all of the Site (and derivative works
              thereof) in any media formats and through any media channels.
            </li>
          </ol>
        </li>
        <li>
          <strong>Third Party Content</strong>. Through the Site, you will have
          the ability to access and/or use content provided by third parties.
          The Company cannot guarantee that such third-party content will be
          free of material you may find objectionable or otherwise. The Company
          disclaims any responsibility or liability related to your access or
          use of any third-party content.
        </li>
        <li>
          <strong>Links to other Websites</strong>
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              <strong>Links</strong>. The Site may contain links to third-party
              websites or resources. You acknowledge and agree that [PARTY A] is
              not responsible or liable for:
              <ol class="decimal_type" style="list-style-type: lower-alpha">
                <li>
                  the availability or accuracy of such websites or resources; or
                </li>
                <li>
                  the content, products, or services on or available from such
                  websites or resources.
                </li>
              </ol>
            </li>
            <li>
              <strong>No Endorsement</strong>. Links to such websites or
              resources do not imply any endorsement by [PARTY A] of those
              websites or resources.
            </li>
            <li>
              <strong>Assumption of Risk</strong>. [PARTY B] acknowledges sole
              responsibility for and assumes all risk arising from your use of
              any such websites or resources.
            </li>
          </ol>
        </li>
        <li>
          <strong>Privacy</strong>. For information about how the Company
          collects, uses, and shares your information, please review our Privacy
          Policy. You agree that, by using the Site, you consent to the
          collection, use, and sharing (as set forth in the Privacy Policy) of
          that&nbsp;information (including the transfer of this information to
          the United States or other countries for the Company&apos;s storage,
          processing, and use).
        </li>
        <li>
          <strong>Copyright Policy</strong>. The Company respects the
          intellectual property rights of others and expects users of the
          Service to do the same. We will respond to notices of alleged
          copyright infringement that comply with applicable law and are
          properly provided to us. If you believe that your Content has been
          copied in a way that constitutes copyright infringement, please
          provide our copyright agent with the following information in
          accordance with the Digital Millennium Copyright Act:
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              a physical or electronic signature of the copyright owner or a
              person authorized to act on their behalf,
            </li>
            <li>
              identification of the copyrighted work claimed to have been
              infringed,
            </li>
            <li>
              identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material,
            </li>
            <li>
              your contact information, including your address, telephone
              number, and an email address,
            </li>
            <li>
              a statement by you that you have a good faith belief that use of
              the material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law, and
            </li>
            <li>
              a statement that the information in the notification is accurate,
              and, under penalty of perjury, that you are authorized to act on
              behalf of the copyright owner.
            </li>
          </ol>
        </li>
        <li>
          <strong>Promotions</strong>. Some users may promote competitions,
          promotions, prize draws, and other similar opportunities on the Site
          (&quot;Third Party Competitions&quot;). The Company is not the sponsor
          or promoter of these Third-Party Competitions and does not bear any
          responsibility or liability for the actions or inactions of any third
          parties who organize, administer, or are otherwise involved in any of
          promotion of these Third-Party Competitions. If you wish to
          participate in any of these Third-Party Competitions, you are
          responsible for reading and ensuring that you understand the
          applicable rules and any eligibility requirements and are lawfully
          able to participate in such Third Party Competitions in your country
          of residence. If you wish to run your own Third-Party Competition on
          the Platform, you are free to do so provided you comply with our
          Competition Terms.
        </li>
        <li>
          <strong>Termination</strong>
          <ol class="decimal_type" style="list-style-type: undefined">
            <li>
              <strong>Termination on Notice</strong>. Either party may terminate
              this agreement for any reason on [TERMINATION NOTICE BUSINESS
              DAYS]&nbsp;business days&rsquo; notice to the other party.
            </li>
            <li>
              <strong>Termination for Material Breach</strong>. Each party may
              terminate this agreement with immediate effect by delivering
              notice of the termination to the other party, if
              <ol type="a" style="list-style-type: lower-alpha">
                <li>
                  the other party fails to perform, has made, or makes any
                  inaccuracy in, or otherwise materially breaches, any of its
                  obligations, covenants, or representations, and
                </li>
                <li>
                  the failure, inaccuracy, or breach continues for a period of
                  [BREACH CONTINUATION DAYS] Business Days&apos; after the
                  injured party delivers notice to the breaching party
                  reasonably detailing the breach.
                </li>
              </ol>
            </li>
            <li>
              <strong>Termination for&nbsp;Failure of Condition</strong>. Either
              party may terminate this agreement with immediate effect by
              delivering notice of the termination to the other party, if either
              <ol type="a" style="list-style-type: lower-alpha">
                <li>
                  any of the condition&rsquo;s precedent set out in [CONDITIONS
                  ON OBLIGATIONS OF ALL PARTIES] have not been, or it becomes
                  apparent that any such conditions will not be, fulfilled by
                  [DATE], and
                </li>
                <li>
                  such non-fulfillment&nbsp;was not due to the failure of the
                  injured party to perform or comply with any&nbsp;of its
                  representations, warranties,&nbsp;covenants, or conditions to
                  be performed or complied with,&nbsp;or
                </li>
                <li>
                  any of the conditions specifically applicable to the other
                  party have not been, or it becomes apparent that any such
                  conditions will not be, fulfilled by [DATE].
                </li>
              </ol>
            </li>
            <li>
              <strong>Termination for Insolvency</strong>. If either party
              becomes insolvent, bankrupt, or enters receivership, dissolution,
              or liquidation, the other party may terminate this agreement with
              immediate effect.
            </li>
            <li>
              <strong>Termination Because of&nbsp;Law or Order</strong>. Either
              party may terminate this agreement with immediate effect if
              <ol style="list-style-type: lower-alpha">
                <li>
                  there is or becomes any Law that makes the performance of the
                  terms of this agreement illegal or otherwise prohibited, or
                </li>
                <li>
                  any Governmental Authority issues an Order restraining or
                  enjoining the transactions under&nbsp;this agreement.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Disclaimers.</strong> The Site is provided &quot;as is,&quot;
          without any warranties of any kind. To the fullest extent permissible
          under applicable Law, the Company disclaims all such warranties,
          express or implied, including, but not limited to, warranties of
          merchantability, fitness for a particular purpose, non-infringement,
          accuracy, freedom from errors, suitability of content, or
          availability.
        </li>
        <li>
          <strong>Limitation on Liability</strong>. Neither party will be liable
          for breach-of-contract damages that are remote or speculative, or that
          the breaching party could not reasonably have foreseen on entry into
          this agreement.
        </li>
        <li>
          <strong>Feedback.</strong> We welcome any comment, question, and
          communication at [FEEDBACK LINK].
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
.main_list {
  margin-left: -0.5in;
}

@media only screen and (max-width: 600px) {
  .main_list {
    margin-left: -0.2in;
  }
}
@media only screen and (max-width: 900px) {
  .main_list {
    margin-left: -0.1in;
  }
}
.terms-container ol.decimal_type {
  counter-reset: item;
}
.terms-container .decimal_type > li {
  display: block;
}
.terms-container .decimal_type > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
</style>
